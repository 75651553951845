import type { DataObject } from 'o365-dataobject';
import type { default as DataColumnGroup, ColumnGroupOptions } from './DataGrid.DataColumnGroup.ts';
import DataColumn from './DataGrid.DataColumn.ts';
import { logger } from 'o365-utils';

// ILayoutColumnProperties
export type ILayoutColumnPropertiesPlaceholder = any;

export default class DataColumns {
    /** Unused width used for flexWidth */
    private _unusedWidth: number = 0;
    /** Main columns array */
    columns: DataColumn[] = [];

    /** When false will hide the 'o365_System' column  */
    showSystemColumn: boolean;
    /** When false will hide the 'o365_MultiSelect' column  */
    showMultiSelect: boolean;
    /** When false will hide the 'o365_Action' column  */
    showActionColumn: boolean;
    
    initialColumns: any[];
    initialColumnsMap: any;
    initialGroups: any[];
    columnGroups?: ColumnGroupOptions[][];
    leftPinnedWidth?: number;
    centerWidth?: number;
    rightPinnedWidth?: number;
    addSpecialGridColumns: boolean;
    get unusedWidth() { return this._unusedWidth; }
    set unusedWidth(value) {
        this._unusedWidth = value;
        const totalFlexWidth = this.columns.reduce((sum, col) => {
            if (col.flexWidth && !col.hide) {
                sum += +col.flexWidth;
            }
            return sum;
        }, 0); 
        if (!totalFlexWidth) { return; }
        this.columns.forEach(col => {
            if (!col.flexWidth || col.hide) { return; }
            col.widthAdjustment = (value * col.flexWidth) / totalFlexWidth
        });
    }

    leftColumns: DataColumn[] = [];
    centerColumns: DataColumn[] = [];
    rightColumns: DataColumn[] = [];
    hasPinnedLeftColumns: boolean = false;
    hasPinnedRightColumns: boolean = false;

    private _columnsWatcher?: ColumnsWatcher;

    private _isTable: boolean;

    // get leftColumns() {
    //     return this.columns.filter(column => column.pinned === 'left');
    // }

    // get centerColumns() {
    //     return this.columns.filter(column => !column.pinned);
    // }

    // get rightColumns() {
    //     return this.columns.filter(column => column.pinned === 'right');
    // }

    // get hasPinnedLeftColumns() {
    //     return !this.columns.every(col => col.pinned !== 'left');
    // }
    // get hasPinnedRightColumns() {
    //     return !this.columns.every(col => col.pinned !== 'right');
    // }

    get visibleColumns() {
        return this.columns.filter(x => !x.hide);
    }

    get hasGroupedColumns() {
        return this.columnGroups && this.columnGroups.length > 0;
    }

    constructor(columnDefinitions: any[], pDataObject: DataObject, pOptions?: {
        isDataTable?: boolean
    }) {
        this.initialColumnsMap = {};

        this._isTable = pOptions?.isDataTable ?? false;

        this.showSystemColumn = !pOptions?.initialColumnsOptions?.hideSystemColumn;
        this.showMultiSelect = !pOptions?.initialColumnsOptions?.hideMultiSelectColumn;
        this.showActionColumn = !pOptions?.initialColumnsOptions?.hideActionColumn;
        this.addSpecialGridColumns = pOptions?.initialColumnsOptions?.addSpecialGridColumns ?? true;

        const groups: any[] = [];
        const flattenColumns = (columns: any[], groupLevel = 0, groupId = null) => {
            const flatColumns: any[] = [];
            columns.forEach((column) => {
                if (column.children) {
                    if (groupId) { column.parentGroupId = groupId; }
                    if (!groups[groupLevel]) { groups[groupLevel] = []; }
                    groups[groupLevel].push(column);
                    flatColumns.push(...flattenColumns(column.children, groupLevel + 1, column.groupId));
                } else {
                    if (groupId) { column.parentGroupId = groupId; }
                    flatColumns.push(column);
                }
            })
            return flatColumns;
        };

        this.initialColumns = flattenColumns(columnDefinitions);
        this.initialGroups = groups;
        if (pDataObject) {
            this.initialColumns.forEach(col => {
                const fieldName = col.field ?? col.name;
                // const vTmp = pDataObject.fields.fields.find(x => x.name === fieldName);
                const vTmp = pDataObject.fields[fieldName];
                const hasViefDefinition = vTmp && !!(pDataObject.fields.viewDefinition.find(x => x.fieldName === fieldName) ?? pDataObject.fields.uniqueTableDefinition?.find(x => x.fieldName === fieldName));
                if (col.field && pDataObject.fields[col.field] == null) {
                    logger.warn(`Column recieved a field (${col.field}) that does not exist does not exist in ${pDataObject.id}`);
                }
                if (vTmp && vTmp.type) {
                    col.type = vTmp.type;
                }
                if (vTmp && !vTmp.required && hasViefDefinition && col.required == null) {
                    if (!vTmp.nullable && !vTmp.hasDefault && !vTmp.readOnly) {
                        col.required = true;
                        vTmp.required = true;
                    } else {
                        const idField = pDataObject.fields[`${vTmp.name}_ID`];
                        if (idField && !idField.nullable && !idField.hasDefault && !idField.readOnly) {
                            col.required = true;
                        } else if (col.boundFields && col.boundFields.length > 0) {
                            col.required = col.boundFields.some((field: string) => {
                                const boundField = pDataObject.fields[field];
                                return boundField && !boundField.nullable && !boundField.hasDefault && !boundField.readOnly;
                            });
                        }
                    }
                } else if (vTmp?.required) {
                    col.required = true;
                }
                if (vTmp && vTmp.caption && col.headerName == null) {
                    col.headerName = vTmp.caption;
                }else if(vTmp && col.headerName !== null){
                    vTmp.customCaption = col.headerName;
                }
            })
        }
        this.setInitalColumns(pOptions?.initialColumnsOptions);
    }

    setInitalColumns(pOptions) {
        this.columns = this.initialColumns.map((column, index) => {
            const colId = column.colId ?? column.field ?? column.column;
            this.initialColumnsMap[colId] = column;
            const dataColumn = new DataColumn(column)
            if (column.width == null) { column.width = dataColumn.width; }
            return dataColumn;
        });
        if (this.initialGroups.length > 0) {
            const mapGroupsToColumns = (groups) => {
                return groups.map(group => {
                    if (!group.children) { return group; }

                    const children = group.children.map(child => {
                        const colId = child.colId ?? child.column ?? child.field;
                        if (colId) {
                            return this.getColumn(colId);
                        } else {
                            return child;
                        }
                    });

                    return { ...group, children: children };
                });
            };

            this.columnGroups = this.initialGroups.map(groupLevel => mapGroupsToColumns(groupLevel));

            // this.columnGroups = this.initialGroups.map((group) => {
            //     const children = group.children.map(column => this.getColumn(column.field));
            //     return { ...group, children: children };
            // }).map(group => new DataColumnGroup(group));

            // Create individual DataColumnGroups for columns without groupId
            // this.columns.forEach((column) => {
            //     if (!column.groupId) {
            //         column.groupId = window.crypto.randomUUID();
            //         new DataColumnGroup({children: [column], groupId: column.groupId});
            //     }
            // });
        }

        if (this.addSpecialGridColumns) {
            this.columns.unshift(new DataColumn({
                colId: 'o365_MultiSelect',
                class: 'custom-cell',
                headerName: '',
                width: 32,
                hide: pOptions?.hideMultiSelectColumn || false,
                editable: false,
                pinned: this._isTable ? undefined :'left',
                cellRenderer: 'MultiSelectColumnCell', // *change
                disableResize: true,
                filter: false,
                cellStyles: [{ 'text-align': 'center' }],
                left: 0,
                sortable: false,
                disableMenu: true,
                suppressMovable: true,
                // suppressNavigable: true,
                // suppressSelection: true,
                singleClickEdit: false,
            }));
            this.columns.unshift(new DataColumn({
                colId: 'o365_System',
                headerName: '',
                width: 30,
                hide: pOptions?.hideSystemColumn || false,
                editable: false,
                pinned: this._isTable ? undefined : 'left',
                disableResize: true,
                filter: false,
                cellStyles: [{ 'text-align': 'center' }],
                left: 0,
                sortable: false,
                disableMenu: true,
                suppressMovable: true,
                // suppressNavigable: true,
                // suppressSelection: true,
                singleClickEdit: false,
            }));
            this.columns.push(new DataColumn({
                colId: 'o365_Action',
                headerName: '',
                width: 28,
                hide: pOptions?.hideActionColumn || false,
                cellRenderer: 'ActionColumnCell', // *change
                editable: false,
                disableResize: true,
                filter: false,
                sortable: false,
                disableMenu: true,
                suppressMovable: true,
                // suppressNavigable: true,
                // suppressSelection: true,
                cellStyles: [{ 'text-align': 'center' }, { 'padding': '0px' }, { 'padding-top': '2px' }],
                singleClickEdit: false,
            }));
        }

        this.columns.forEach((col, index) => {
            if (!col['order']) col['order'] = index;
        });

        this.leftPinnedWidth = 0;
        this.centerWidth = 0;
        this.rightPinnedWidth = 0;

        this.updateWidths();

    }
    static fromDataObject(dataObject: DataObject) {
        const fields = dataObject.fields.fields.map(column => ({
            field: column.name,
            headerName: column.caption,
            editable: true,
        }));
        return new DataColumns(fields, dataObject);
    }

    // DELETE
    getLeft(column) {
        let left = 0;
        const field = column.field;
        const pinned = column.pinned;

        if (pinned) {
            this.columns.filter(col => col.pinned === pinned).every(col => {
                if (col.field === field) { return; }
                if (!col.hide) { left += col.width; }
                return true;
            });
        } else {
            for (let key in this.columns) {
                if (this.columns[key].field === field) { break; }
                if (!this.columns[key].hide && !this.columns[key].pinned) {
                    left += this.columns[key].width;
                }
            }
        }

        return left;
    }

    getColumn(columnId) {
        return this.columns.find(col => col.colId === columnId);
    }

    setColumnProperty(columnId, property, value) {
        if (property === "field" || property === 'colId') { return; }
        const column = this.getColumn(columnId);
        if ((property === 'width' || property === '_width') && (typeof value !== 'number' || isNaN(value))) { logger.warn(`${columnId} tried setting width to ${value}`); return; } 
        if (column) {
            column[property] = value;
        }
    }

    setColumnOrder(pColumn: DataColumn, pOrder: number, pForceSet = false, pTrackChange = true) {
        if (pColumn.suppressMovable && pForceSet) { return; }
        const systemColumns: Record<string, DataColumn> = {};
        const normalColumns: DataColumn[] = [];

        if (pOrder <= 2) { 
            pOrder = 0;
        } else {
            pOrder -= 2;
        }

        this.columns.forEach(col => {
            if (['o365_System', 'o365_MultiSelect', 'o365_Action'].includes(col.colId)) {
                systemColumns[col.colId] = col;
            } else {
                normalColumns.push(col);
            }
        });

        const sortingColumns = normalColumns.sort((a, b) => a.order - b.order);
        const targetColumnIndex = sortingColumns.findIndex(x => x.colId === pColumn.colId);
        pColumn.order = pOrder;
        sortingColumns.splice(targetColumnIndex, 1);
        sortingColumns.splice(pOrder, 0, pColumn);

        const sortedColumns = [
            systemColumns['o365_System'],
            systemColumns['o365_MultiSelect'],
            ...sortingColumns,
            systemColumns['o365_Action']
        ];

        sortedColumns.filter(col => col != null).forEach((col, index) => {
            col['order'] = index;
        });

        if (pTrackChange) {
            pColumn.trackChange('order', pColumn.order);
        }
    }

    getColumnsLayout() {
        return this.columns.map(col => ({ colId: col.colId, ...col.propertyChanges }));
    }

    getBaseColumnsLayout() {
        const getColId = (field: any): string => field.colId ?? field.column ?? field.field;
        const baselineColumns: Record<string, ILayoutColumnPropertiesPlaceholder> = {};
        this.initialColumns.forEach((col: ILayoutColumnPropertiesPlaceholder & {
            colId: string,
            field: string,
            width?: string,
            column: string
        }, index: number) => {
            const colId = getColId(col);
            baselineColumns[colId] = {
                order: index + 2,
                width: col.width ? parseInt(col.width) : undefined,
                hide: col.hide ?? false,
                pinned: col.pinned ?? null,
                hideFromChooser: col.hideFromChooser
            };
        });
        return baselineColumns;
    }

    resetColumnLayout() {
        const systemCols = this.columns.filter(col => col.colId === 'o365_System' || col.colId === 'o365_MultiSelect').length;

        this.columns.forEach(col => col.propertyChanges = {});

        const multiSelectCol = this.columns.find(x => x.colId == 'o365_MultiSelect');
        if (multiSelectCol) {
            multiSelectCol._hide = !this.showMultiSelect;
        }

        this.initialColumns.forEach((col, index) => {
            const colKey = col.colId ?? col.field ?? col.column;
            const column = this.getColumn(colKey);
            if (column == null) { logger.warn(`Could not find ${colKey} in columns list while resetting column layout`); return; }
            column._width = parseInt(col.width) || column.getDefaultWidthForColumn(column.type);
            column._hide = col.hide ? col.hide : false;
            column._pinned = col.pinned;
            column.order = index + systemCols;
            const flexWidth = col.flexWidth ?? col.flexwidth;
            if (flexWidth) {
                column.flexWidth = parseInt(flexWidth);
            }
        });
        this.updateColumnArrays();
        this.updateWidths();
    }

    updateWidths() {
        let vSumLeft = 0, vSumRight = 0, vSum = 0;
        let vAdjLeft = 0, vAdjRight = 0, vAdj = 0;
        this.columns.sort((a, b) => a.order - b.order);
        this.columns.filter(c => !c.hide).forEach((col, index) => {

            if (col.pinned) {
                if (col.pinned === 'left') {
                    if (col.left !== vSumLeft + vAdjLeft) {
                        col.left = vSumLeft + vAdjLeft;
                    }
                    vAdjLeft += col.widthAdjustment;
                    vSumLeft += col.width;
                } else {
                    if (col.left !== vSumRight + vAdjRight) {
                        col.left = vSumRight + vAdjRight;
                    }
                    vAdjRight += col.widthAdjustment;
                    vSumRight += col.width;
                }
            } else {
                if (col.left !== vSum + vAdj) {
                    col.left = vSum + vAdj
                }
                vAdj += col.widthAdjustment;
                vSum += col.width;

            }
        });

        if (this.centerWidth !== vSum) {
            this.centerWidth = vSum;
        }
        if (this.leftPinnedWidth !== vSumLeft) {
            this.leftPinnedWidth = vSumLeft;
        }
        if (this.rightPinnedWidth !== vSumRight) {
            this.rightPinnedWidth = vSumRight;
        }

    }

    getLeftCalculation() {

    }

    getGroup(groupRow, groupId): DataColumnGroup {
        return this.columnGroups?.[groupRow]?.find(group => group.groupId === groupId);
    }

    getNextColumn(column) {
        if (column.order + 1 < this.columns.length) {
            return this.columns[column.order + 1];
        }
    }

    getInitialColumnsJSON() {
        const getId = (col: any) => col.colId ?? col.column ?? col.field;
        return this.initialColumns.reduce((json, col) => {
            const id = getId(col);
            json[id] = {
                colId: id,
                hide: col.hide ?? false,
                pinned: col.pinned ?? null,
                width: col.width
            };
            return json;
        }, {});
        // return this.initialColumns.map(col => ({
        //     field: col.field,
        //     hide: col.hide,
        //     order: col.order,
        //     pinned: col.pinned,
        // }));
    }

    updateColumnArrays() {
        const leftColumns = [];
        const centerColumns = [];
        const rightColumns = [];
        let hasPinnedLeftColumns = false;
        let hasPinnedRightColumns = false;

        const resort = [];

        this.columns.forEach((col, colIndex) => {
            switch (col.pinned) {
                case 'left':
                    leftColumns.push(col);
                    if (colIndex !== leftColumns.length - 1) {
                        resort.push({ col: col, position: leftColumns.length - 1 });
                    }
                    hasPinnedLeftColumns = true;
                    break;
                case 'right':
                    rightColumns.push(col);
                    hasPinnedRightColumns = true;
                    break;
                default:
                    centerColumns.push(col);
                    break;
            }
        });

        resort.forEach(sortable => {
            this.setColumnOrder(sortable.col, sortable.position, true, false);
        });

        this.leftColumns = leftColumns;
        this.centerColumns = centerColumns;
        this.rightColumns = rightColumns;
        this.hasPinnedLeftColumns = hasPinnedLeftColumns;
        this.hasPinnedRightColumns = hasPinnedRightColumns;
    }

    setupWatchers(watchFn, handler) {
        this._columnsWatcher = new ColumnsWatcher(watchFn, handler);
        this.columns.forEach(column => {
            this._columnsWatcher.add(column);
        });
    }

    addWatcher(column: DataColumn) { this._columnsWatcher.add(column); }
    removeWatcher(colId: string) { this._columnsWatcher.remove(colId); }

    removeWatchers() {
        this._columnsWatcher.removeAll();
    }

    /** Add group definition to data columns */
    addGroup(groupDef: { groupId: string, headerName?: string, children?: any[] }) {
        if (this.initialGroups[0] == null) { this.initialGroups[0] = []; }

        this.initialGroups[0].push(groupDef);
        if (this.columnGroups[0] == null) { this.columnGroups[0] = []; }

        const parseGroupDef = (def: typeof groupDef) => {
            if (!def.children) { return def; }
            const children = def.children.map(child => {
                const colId = child.colId ?? child.column ?? child.field;
                if (colId) {
                    return this.getColumn(colId);
                } else {
                    return child;
                }
            });

            return { ...def, children: children };
        };

        this.columnGroups[0].push(parseGroupDef(groupDef));
    }
}

class ColumnsWatcher {
    private _watch: Function;
    private _handler: Function;
    private columns: { [key: string]: Function[] }
    constructor(watcherFn: Function, handler: Function) {
        this._watch = watcherFn;
        this._handler = handler;
        this.columns = {};
    }

    add(column: DataColumn) {
        const key = column.colId;
        this.columns[key] = [
            this.generateWatcher(column, 'width'),
            this.generateWatcher(column, 'pinned'),
            this.generateWatcher(column, 'order'),
            this.generateWatcher(column, 'hide'),
        ];
    }

    remove(colId: string) {
        this.columns[colId]?.forEach(watcher => watcher());
        delete this.columns[colId];
    }

    removeAll() {
        Object.keys(this.columns).forEach(key => {
            this.remove(key);
        });
    }

    generateWatcher(column: DataColumn, key: string) {
        return this._watch(() => column[key], this._handler);
    }
}